import { EnvVariables } from "./";

const env: EnvVariables = {
  APP_DOMAIN: "skyebrowse.com",
  API_URL: "https://api.skyebrowse.com/",
  API_V3_URL: "https://api.skyebrowse.com/",
  MAP_URL: "https://map.skyebrowse.com/",
  UPLOAD_BUCKET_URL: "skyebrowse-production-uploads.s3.amazonaws.com",
  GOOGLE_MAPS_API_KEY: "AIzaSyAo-rOZ0gwSCQA1Dl3j_fFfYxbmxP8f7wc",
  GOOGLE_ANALYTIC_API_KEY: "G-V5C34E46J1",
  STRIPE_PRICING_TABLE_ID: "dummy",
  STRIPE_PUBLISHABLE_KEY: "pk_live_xUZRkUXWM4D3eAT2tdPi8bjj00qHuF8XEd",
  STRIPE_CUSTOMER_PORTAL_ID: "28o03j01FbQk21G3cc",
  FACEBOOK_CONVERSION_API_KEY: "EAAzXiB0rEB8BO7pmNfj1RZCKo9ZA8wTnQo1ZBZAawXILd91X0zjvvUkla4bgwOUf63dUu6oNtwZBSZBaWlzfidByC6qzHBbEgIHhR19R2X8WPRjrC3DRfgorkwytYtKh1NtjZAShD5FcbcsP512nP7kM6OD3vkZCcKZB3qah653hoMC5YLbiKyasDhCxhuZBUJcCTRUgZDZD",
  FACEBOOK_PIXEL_ID: "909411270832318",
};

export default env;
